import { isValidAdmin, isValidRevenue360 } from './user';

// default display restrictions per specified page (by pathname) that affects
// which navtabs user can see and which pages user can navigate to (manually or programmatically)
export const getPageDisplayRestrictions = (user, status, driver_app_access) => {
    const { role, company } = user ?? {};

    // TODO: cleanup which pages a company can access
    return {
        '/login': !user && status !== 'authenticating',
        '/settings': status === 'authenticated',
        '/admin': isValidAdmin(user),
        '/smartbid': status === 'authenticated' && company !== 'ARL Logistics',
        '/optirates':
            ['Everest', 'DTH Expeditors Inc'].includes(company) &&
            ['admin', 'executive', 'user'].includes(role),
        '/revenue360': isValidRevenue360(user) && company !== 'ARL Logistics',
        '/lisa-analytics': ['admin', 'executive'].includes(role),
        '/market-map':
            ['admin', 'executive', 'user'].includes(role) &&
            company !== 'ARL Logistics',
        '/allowlist':
            ['admin', 'executive', 'user'].includes(role) &&
            company !== 'ARL Logistics',
        '/driver-manager':
            Boolean(driver_app_access) &&
            ['admin', 'executive'].includes(role) &&
            company !== 'ARL Logistics',
        '/vehicle-manager':
            ['admin', 'executive', 'user'].includes(role) &&
            company !== 'ARL Logistics',
    };
};
