import { Input } from 'components/Form';
import { useFormContext } from 'react-hook-form';

export const CUSTOM_RATES_VEHICLES = [
    { vehicle_class: 'Tractor Trailer', display_name: 'Van' },
    { vehicle_class: 'Refrigerated', display_name: 'Refrigerated' },
    { vehicle_class: '53 Flatbed', display_name: "53' Flatbed" },
    { vehicle_class: '48 Flatbed', display_name: "48' Flatbed" },
];

export const CUSTOM_RATES_DISTANCES = [
    { id: 0, min: undefined, max: 200, label: '<200 miles' },
    { id: 1, min: 201, max: 500, label: '201-500 miles' },
    { id: 2, min: 501, max: 1000, label: '501-1000 miles' },
    { id: 3, min: 1001, max: 1500, label: '1001-1500 miles' },
    { id: 4, min: 1501, max: undefined, label: '1500+ miles' },
];

const CustomRateGrid = ({ name_prefix }) => {
    const { register, watch, errors } = useFormContext();

    return (
        <table style={{ borderSpacing: '8px' }}>
            <thead style={{ color: 'var(--primary-700)' }}>
                <tr>
                    <th></th>
                    {CUSTOM_RATES_DISTANCES.map(({ id, label }) => (
                        <th key={id}>{label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {CUSTOM_RATES_VEHICLES.map(
                    ({ vehicle_class, display_name }) => (
                        <tr key={vehicle_class}>
                            <th
                                style={{
                                    textAlign: 'right',
                                    color: 'var(--primary-700)',
                                }}
                            >
                                {display_name}
                            </th>
                            {CUSTOM_RATES_DISTANCES.map(
                                ({ id: distance_id }) => (
                                    <td key={`${vehicle_class}.${distance_id}`}>
                                        <Input
                                            type='text'
                                            size='sm'
                                            label={`${name_prefix}.${vehicle_class}.${distance_id}`}
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            placeholder='$/mi'
                                            narrow
                                            hideErrorMessage
                                            validate={(value) =>
                                                !isNaN(Number(value))
                                            }
                                        />
                                    </td>
                                )
                            )}
                        </tr>
                    )
                )}
            </tbody>
        </table>
    );
};

export { CustomRateGrid };
